import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div>
    <div className='flex justify-center shadow-inner'>
        <div className='flex xs:ml-8 md:ml-0 xs:justify-start md:justify-center flex-wrap py-20'>
            <div>
                <h1 className='text-xl'>БИДНИЙ ТУХАЙ</h1>
                <p className='xs:w-80 md:w-96 mt-4'>Таны барааг эрээн-улаанбаатар чиглэлд найдвартай түргэн шуурхай тээвэрлэнэ.</p>
                <img className='xs:h-20 md:block' src='../../icons/logo.png'/>
            </div>
            <div className='xs:mx-0 md:mx-40'>
                <h1 className='text-xl uppercase'>Хуудсууд</h1>
                <Link to="/">
                   <h1 className='mt-4'>Нүүр</h1>
                </Link>
                <Link to="/help">
                <h1>Тусламж</h1>
                </Link>
                <Link to="/address">
                <h1>Хаяг холбох</h1>
                </Link>
            </div>
            <div className='w-80'>
                <h1 className='text-xl uppercase'>бидэнтэй холбогдох</h1>
                <h1 className='mt-4'>Хаяг 1: Хан уул дүүрэг 2-р хороо 19-р хороолол 9-р байрны  1-р орц B1 давхар</h1>
                <h1 className='mt-4'>Хаяг 2: 19 үйлчилгээний төвийн зүүн талд 21-р байр зүүн урд булангийн өргөтгөлийн хаалга (орцгүй талдаа)</h1>
                <h1 className='mt-2 font-bold'>Цагийн хуваарь : </h1>
                <div className='mt-1'>Даваа 10:30 - 18:30</div>
                <div className='mt-1'>Мягмар 10:30 - 18:30</div>
                <div className='mt-1'>Лхагва 14:00 - 16:30 (Цайны цаггүй)</div>
                <div className='mt-1'>Пүрэв 10:30 - 18:30</div>
                <div className='mt-1'>Баасан 10:30 - 18:30</div>

                <div className='mt-1'>Бямба 11:00 - 15:00</div>
                <div className='mt-1'>Ням гаригт амарна</div>

                <div className='mt-2'>Цайны цаг 14:00 - 15:00</div>
                <h1 className='mt-2'>Утас : 9699-9064 9699-9974</h1>
            </div>
        </div>
        
    </div>
    <div className='bg-blue-600 text-center text-white py-4'>
           2022 он WWW.GIILEN.MN бүтээв 
        </div>
    </div>
  )
}

export default Footer